"use strict";

// Import only the necessary modules
import $ from '../vendor/jquery.min.js';
import '../vendor/popper/popper.min.js';
import bootstrap from '../vendor/bootstrap.bundle.min.js';

// Dynamically import other modules
const loadAOS = () => import('../vendor/aos/aos');
const loadIsotope = () => import('isotope-layout');
const loadGLightbox = () => import('../vendor/glightbox/js/glightbox.min.js');
const loadSwiper = () => import('../vendor/swiper/swiper-bundle.min.js');

// Service Worker Registration
if ('serviceWorker' in navigator) {
  const serviceWorkerPath = '/service-worker.js';
  window.addEventListener('load', () => {
    navigator.serviceWorker.register(serviceWorkerPath)
      .then(registration => {
        console.log('Service Worker registered successfully:', registration.scope);
      })
      .catch(error => {
        console.log('Service Worker registration failed:', error);
      });
  });
}

document.addEventListener('DOMContentLoaded', async () => {
  // Helper functions
  const select = (el, all = false) => {
    try {
      return all ? [...document.querySelectorAll(el)] : document.querySelector(el);
    } catch (error) {
      console.error(`Error selecting element(s) ${el}:`, error);
      return all ? [] : null;
    }
  };
  
  const on = (type, el, listener, all = false) => {
    try {
      (all ? select(el, true) : [select(el)]).forEach(e => {
        if (e) {
          e.addEventListener(type, listener);
        }
      });
    } catch (error) {
      console.error(`Error adding event listener to ${el}:`, error);
    }
  };

  // Navbar links active state on scroll
  const navbarlinksActive = () => {
    const navbarlinks = select('#navbar .scrollto', true);
    const position = window.scrollY + 200;
    navbarlinks.forEach(navbarlink => {
      if (!navbarlink || !navbarlink.hash) return;
      const section = select(navbarlink.hash);
      if (!section) return;
      navbarlink.classList.toggle('active', position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight));
    });
  };

  // Scroll with offset
  const scrollto = (el) => {
    const header = select('#header');
    if (!header) return;
    const offset = header.offsetHeight;
    const targetEl = select(el);
    if (!targetEl) return;
    const elementPos = targetEl.offsetTop;
    window.scrollTo({
      top: elementPos - offset,
      behavior: 'smooth'
    });
  };

  // Header scroll class
  const headerScrolled = () => {
    const selectHeader = select('#header');
    if (selectHeader) {
      selectHeader.classList.toggle('header-scrolled', window.scrollY > 100);
    }
  };

  // Back to top button
  const toggleBacktotop = () => {
    const backtotop = select('.back-to-top');
    if (backtotop) {
      backtotop.classList.toggle('active', window.scrollY > 100);
    }
  };

  // Mobile nav toggle
  on('click', '.mobile-nav-toggle', function(e) {
    const navbar = select('#navbar');
    if (navbar) {
      navbar.classList.toggle('navbar-mobile');
      this.classList.toggle('bi-list');
      this.classList.toggle('bi-x');
    }
  });

  // Mobile nav dropdowns activate
  on('click', '.navbar .dropdown > a', function(e) {
    const navbar = select('#navbar');
    if (navbar && navbar.classList.contains('navbar-mobile')) {
      e.preventDefault();
      const nextEl = this.nextElementSibling;
      if (nextEl) {
        nextEl.classList.toggle('dropdown-active');
      }
    }
  }, true);

  // Scroll with offset on page load with hash links in the url
  window.addEventListener('load', () => {
    if (window.location.hash) {
      const element = select(window.location.hash);
      if (element) {
        scrollto(window.location.hash);
      }
    }
  });

  // Animation on scroll
  const initAOS = async () => {
    try {
      const AOS = await loadAOS();
      AOS.init({
        duration: 400,
        easing: 'ease-in-out',
        once: true,
        mirror: false
      });
    } catch (error) {
      console.error('Error initializing AOS:', error);
    }
  };

  // Subscribe modal
  const setupSubscribeModal = () => {
    const modalEl = document.getElementById('subscribeModal');
    const alertContainer = document.getElementById('alertContainer');
    const subscribeEmail = document.getElementById('subscriber_email');
    const subscribeUser = document.getElementById('subscribeUser');

    if (!modalEl || !alertContainer || !subscribeEmail || !subscribeUser) {
      console.error('One or more elements for subscribe modal not found');
      return;
    }

    const myModal = new bootstrap.Modal(modalEl, { focus: true });
    myModal.show();

    modalEl.addEventListener('shown.bs.modal', () => {
      subscribeEmail.focus();
      subscribeEmail.value = '';
    });

    subscribeEmail.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        subscribeUser.click();
      }
    });

    const handleSubscribe = async (email) => {
      myModal.hide();
      try {
        const response = await fetch('/subscribe', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email })
        });
        const data = await response.json();
        const alertClass = data.success ? 'alert-success' : 'alert-danger';
        alertContainer.innerHTML = `
          <div style="width: 100%; text-align:center;" class="alert alert-dismissable ${alertClass} fade show" role="alert">
            ${data.message}
            <button type="button" id="#close-alert" class="btn-close" style="float: right;" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>`;
      } catch (error) {
        console.error('Error in handleSubscribe:', error);
        alertContainer.innerHTML = `
          <div style="width: 100%; text-align:center;" class="alert alert-danger fade show" role="alert">
            An error occurred. Please try again later.
            <button type="button" id="#close-alert" class="btn-close" style="float: right;" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>`;
      }
      setTimeout(() => {
        const alert = alertContainer.querySelector('.alert');
        if (alert) {
          alert.classList.remove('show');
          alert.classList.add('fade');
          setTimeout(() => alertContainer.innerHTML = '', 150);
        }
      }, 4000);
    };

    subscribeUser.addEventListener('click', (event) => {
      event.preventDefault();
      handleSubscribe(subscribeEmail.value);
    });

    // Footer subscribe
    const footSubscribeEmail = document.getElementById('foot_sub_email');
    const footSubscribeUser = document.getElementById('footSubscribeUser');

    if (footSubscribeEmail && footSubscribeUser) {
      footSubscribeEmail.value = '';
      footSubscribeUser.addEventListener('click', (event) => {
        event.preventDefault();
        handleSubscribe(footSubscribeEmail.value);
      });
    }
  };

  // Toggle scrolled class
  const toggleScrolled = () => {
    const selectBody = document.querySelector('body');
    const selectHeader = document.querySelector('#header');
    if (!selectHeader || (!selectHeader.classList.contains('scroll-up-sticky') && !selectHeader.classList.contains('sticky-top') && !selectHeader.classList.contains('fixed-top'))) return;
    if (selectBody) {
      selectBody.classList.toggle('scrolled', window.scrollY > 100);
    }
  };

  // Mobile nav toggle
  const mobileNavToggleBtn = document.querySelector('.mobile-nav-toggle');
  const mobileNavToogle = () => {
    const body = document.querySelector('body');
    if (body && mobileNavToggleBtn) {
      body.classList.toggle('mobile-nav-active');
      mobileNavToggleBtn.classList.toggle('bi-list');
      mobileNavToggleBtn.classList.toggle('bi-x');
    }
  };

  // Hide mobile nav on same-page/hash links
  document.querySelectorAll('#navmenu a').forEach(navmenu => {
    navmenu.addEventListener('click', () => {
      if (document.querySelector('.mobile-nav-active')) {
        mobileNavToogle();
      }
    });
  });

  // Toggle mobile nav dropdowns
  document.querySelectorAll('.navmenu .toggle-dropdown').forEach(navmenu => {
    navmenu.addEventListener('click', function(e) {
      e.preventDefault();
      if (this.parentNode) {
        this.parentNode.classList.toggle('active');
        const nextSibling = this.parentNode.nextElementSibling;
        if (nextSibling) {
          nextSibling.classList.toggle('dropdown-active');
        }
      }
    });
  });

  // Preloader
  const removePreloader = () => {
    const preloader = document.querySelector('#preloader');
    if (preloader) {
      preloader.remove();
    }
  };

  // Scroll top button
  const scrollTop = document.querySelector('.scroll-top');
  const toggleScrollTop = () => {
    if (scrollTop) {
      scrollTop.classList.toggle('active', window.scrollY > 100);
    }
  };

  // Isotope layout
  const initIsotope = async () => {
    try {
      const Isotope = await loadIsotope();
      const isotopeItems = document.querySelectorAll('.isotope-layout');
      
      isotopeItems.forEach(isotopeItem => {
        const layout = isotopeItem.getAttribute('data-layout') || 'masonry';
        const filter = isotopeItem.getAttribute('data-default-filter') || '*';
        const sort = isotopeItem.getAttribute('data-sort') || 'original-order';

        const container = isotopeItem.querySelector('.isotope-container');
        if (!container) return;

        const filterItems = isotopeItem.querySelectorAll('.isotope-filters li');

        imagesLoaded(container, function() {
          const isotope = new Isotope(container, {
            itemSelector: '.isotope-item',
            layoutMode: layout,
            filter: filter,
            sortBy: sort
          });

          filterItems.forEach(filterItem => {
            filterItem.addEventListener('click', function() {
              const activeFilter = isotopeItem.querySelector('.isotope-filters .filter-active');
              if (activeFilter) {
                activeFilter.classList.remove('filter-active');
              }
              this.classList.add('filter-active');
              
              requestAnimationFrame(() => {
                isotope.arrange({ filter: this.getAttribute('data-filter') });
                if (typeof AOS !== 'undefined') {
                  AOS.refresh();
                }
              });
            });
          });
        });
      });
    } catch (error) {
      console.error('Error initializing Isotope:', error);
    }
  };

  // FAQ Toggle
  const initFAQToggle = () => {
    document.querySelectorAll('.faq-item h3, .faq-item .faq-toggle').forEach((faqItem) => {
      faqItem.addEventListener('click', () => {
        const parentNode = faqItem.parentNode;
        if (parentNode) {
          parentNode.classList.toggle('faq-active');
        }
      });
    });
  };

  // NavMenu scrollspy
  const navmenuScrollspy = () => {
    const navmenulinks = document.querySelectorAll('.navmenu a');
    const position = window.scrollY + 200;

    navmenulinks.forEach(navmenulink => {
      if (!navmenulink.hash) return;
      const section = document.querySelector(navmenulink.hash);
      if (!section) return;

      if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
        document.querySelectorAll('.navmenu a.active').forEach(link => link.classList.remove('active'));
        navmenulink.classList.add('active');
      } else {
        navmenulink.classList.remove('active');
      }
    });
  };

  // Event listeners
  window.addEventListener('load', async () => {
    navbarlinksActive();
    headerScrolled();
    toggleBacktotop();
    setupSubscribeModal();
    removePreloader();
    await initIsotope();
    await initAOS();
  });

  document.addEventListener('scroll', () => {
    navbarlinksActive();
    headerScrolled();
    toggleBacktotop();
    toggleScrolled();
    navmenuScrollspy();
  });

  if (mobileNavToggleBtn) {
    mobileNavToggleBtn.addEventListener('click', mobileNavToogle);
  }

  if (scrollTop) {
    scrollTop.addEventListener('click', (e) => {
      e.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  }

  initFAQToggle();
});